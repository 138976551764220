export const tr = {
  sign_in: 'Giriş yap',
  email: 'E-posta',
  password: 'Şifre',
  i_agree_to_toc: 'Şartlar ve koşulları kabul ediyorum.',
  terms_and_conditions: 'Şartlar ve koşullar',
  privacy_policy: 'Gizlilik politikası',
  already_have_an_account: 'Zaten bir hesabınız var mı?',
  sign_up: 'Kaydol',
  dont_have_an_account: 'Hesabınız yok mu?',
  forgot_password: 'Şifrenizi mi unuttunuz?',
  email_already_in_use: 'E-posta zaten kullanımda',
  weak_password: 'Zayıf şifre',
  too_many_attempts: 'Çok fazla deneme',
  invalid_email_or_password: 'Geçersiz e-posta veya şifre',
  user_already_registered: 'Kullanıcı zaten kayıtlı',
  unexpected_error: 'Beklenmeyen hata',
  audios: 'Sesler',
  name: 'Ad',
  auto: 'Otomatik',
  auto_detect: 'Otomatik tespit',
  speaker_num: 'Konuşmacı Sayısı',
  speaker_num_reason:
    'Konuşmacı sayısını belirtirseniz, daha iyi bir transkripsiyon sonucu sunabiliriz.',
  audio_language: 'Ses Dili',
  your_email: 'E-postanız',
  email_result: 'Size transkripsiyon sonucunu e-posta ile göndereceğiz.',
  provide_language:
    'Ses dilini belirtirseniz, daha iyi bir transkripsiyon sonucu sunabiliriz.',
  upload_audio: 'Ses Yükle',
  upload_file: 'Dosya Yükle',
  process_audio: 'Dosyanız İşleniyor',
  process_audio_des:
    'Sesiniz transkribe edilirken lütfen bekleyin. Bu birkaç dakika sürebilir.',
  uploading: 'Yükleniyor',
  pending: 'Beklemede',
  uploaded: 'Yüklendi',
  failed: 'Başarısız',
  status: 'Durum',
  language: 'Dil',
  speakers: 'Konuşmacılar',
  duration: 'Süre',
  done: 'Tamam',
  close: 'Kapat',
  queuing: 'Sırada',
  created_at: 'Oluşturulma tarihi',
  records: 'Kayıtlar',
  transcribe: 'Transkribe et',
  delete: 'Sil',
  processing: 'İşleniyor',
  from_youtube: "YouTube'dan",
  upload_files: 'Dosyaları yükle',
  place_youtube_link_here: 'YouTube bağlantısını buraya yapıştırın',
  submit: 'Gönder',
  email_notification:
    'Dönüş süresi, ses dosyasının uzunluğuna ve karmaşıklığına bağlıdır. Genel olarak, transkripsiyonunuzu birkaç saat içinde almayı bekleyebilirsiniz. Hazır olduğunda sizi e-posta ile bilgilendireceğiz.',
  filters: 'Filtreler',
  filter: 'Filtre',
  replace: 'Değiştir',
  share_and_export: 'Paylaş ve Dışa Aktar',
  replace_all: 'Tümünü Değiştir',
  next: 'Sonraki',
  previous: 'Önceki',
  find: 'Bul',
  replace_with: 'Bununla değiştir',
  x_of_x: '{current} / {total}',
  scroll_lock: 'Kaydırma Kilidi',
  tags: 'Etiketler',
  bookmark: 'Yer İmi',
  audio: 'Ses',
  cancel: 'İptal',
  export: 'Dışa Aktar',
  export_format: 'Dışa Aktarma formatı',
  copy_text: 'Metni kopyala',
  monologue: 'Monolog',
  include_timestamps: 'Zaman damgalarını dahil et',
  include_speaker_names: 'Konuşmacı isimlerini dahil et',
  combine_same_speaker: 'Aynı konuşmacı bölümlerini birleştir',
  export_mode: 'Dışa Aktarma modu',
  no_preview: 'Önizleme yok',
  copy: 'Kopyala',
  copied: 'Kopyalandı',
  unlock_pro_feature:
    "'Pro' abonelik planını yükselterek bu özelliği kilidini açın.",
  pro_features: 'Pro özellikler',
  pro_minutes: '1800 dakika / ay',
  pro_interact_with_audio: 'Sesle Etkileşim',
  pro_interact_with_audio_des: 'Sesinize sorular sorun',
  pro_summary: 'AI Özeti',
  pro_summary_des: 'Sesinizi özetleyin',
  pro_priority: 'Öncelik Sırası',
  pro_priority_des: 'Ses dosyası öncelikli olarak işlenir',
  pro_multi_speakers: 'Çoklu Konuşmacı Tanıma',
  pro_multi_speakers_des:
    'Farklı konuşmacıları doğru bir şekilde algılar ve etiketler',
  redirect_to_stripe: "Ödemeyi tamamlamak için Stripe'a yönlendirileceksiniz.",
  x_per_month: '{x} / Ay',
  x_per_year: '{x} / Yıl',
  trash: 'Çöp Kutusu',
  dashboard: 'Gösterge Paneli',
  logout: 'Çıkış yap',
  are_you_sure_to_delete: 'Seçilen kayıtları silmek istediğinize emin misiniz?',
  restore: 'Geri Yükle',
  are_you_sure_to_restore:
    'Seçilen kayıtları geri yüklemek istediğinize emin misiniz?',
  deleted_at: 'Silinme tarihi',
  hidden_segment: '{y} dakikadan sonra {x} segment gizlenmiştir.',
  settings: 'Ayarlar',
  subscription: 'Abonelik',
  default_transcription_language: 'Varsayılan Transkripsiyon Dili',
  contact_us: 'Bize Ulaşın',
  security: 'Güvenlik',
  about_us: 'Hakkımızda',
  download_app: 'Uygulamayı İndir',
  download: 'İndir',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Ses yükle',
  no_audio_found: 'Ses bulunamadı',
  please_input_valid_email_first: 'Lütfen önce geçerli bir e-posta girin',
  handling_audio: 'Sesiniz transkribe ediliyor. Lütfen bir an bekleyin.',
  welcome_to_leave:
    'Bu sayfadan ayrılabilirsiniz, transkripsiyon sonucu e-posta ile size gönderilecektir.',
  send_your_result: 'Transkripsiyon sonucunu size e-posta ile göndereceğiz.',
  transcribe_audio_thank:
    'Transkripsiyon hizmetimizi kullandığınız için teşekkür ederiz! Sesinizin transkripsiyonu üzerinde çalışıyoruz ve sabrınız için teşekkür ederiz.',
  transcribe_audio_no_stay:
    'Transkripsiyonunuz biraz zaman alabilir, ancak endişelenmeyin, bu sayfada kalmanız gerekmez. Sonuçlar hazır olduğunda size e-posta göndereceğiz.',
  date: 'Tarih',
  sign_up_to_access_more_functions: 'Daha fazla özelliğe erişmek için kaydolun',
  you_can_use_functions:
    'Giriş yaptıktan sonra aşağıdaki özellikleri kullanabilirsiniz',
  know_more: 'Daha fazla bilgi edinin',
  modify_transcription: 'Metin transkripsiyonunu düzenleyin',
  play_audio: 'Sesi oynat',
  content_filters: 'İçerik filtreleri',
  export_diff_type: 'Farklı türde dosyalar dışa aktar',
  screenshots: 'Ekran görüntüleri',
  free_trial: 'Ücretsiz Deneme',
  pricing: 'Fiyatlandırma',
  faq: 'SSS',
  contact: 'İletişim',
  login: 'Giriş yap',
  register: 'Kayıt ol',
  please_login_before_purchase: 'Satın almadan önce lütfen giriş yapın',
  no_records_found: 'Kayıt bulunamadı',
  x_minutes: '{x} Dakika',
  confirm: 'Onayla',
  notification_mobile_app: 'Mobil Uygulama Bildirimi',
  notification_mobile_app_des:
    'Transkripsiyon sonucu hazır olduğunda mobil uygulama bildirimi alın',
  notification_email: 'E-posta Bildirimi',
  notification_email_des:
    'Transkripsiyon sonucu hazır olduğunda e-posta bildirimi alın',
  video: 'Video',
  or: 'Veya',
  sign_in_with_google: 'Google ile Giriş Yap',
  sign_in_with_apple: 'Apple ile Giriş Yap',
  login_method: 'Giriş Yöntemi',
  delete_account: 'Hesabı Sil',
  delete_account_confirm_3_time: 'Onaylamak için sil düğmesine 3 kez basın',
  popup_blocked: 'Popup engellendi',
  account_info: 'Hesap Bilgileri',
  redeem: 'Kod Kullan',
  redeem_code: 'Kod Kullan',
  summary_des1:
    'Özet hizmeti, transkripsiyon sonucunun bir özetini sağlar. Transkripsiyon sonucunu birkaç cümleye özetleyebilir.',
  summary_des2: 'Eski özetiniz yeni özetle değiştirilecektir.',
  consume_transcription_quota: 'Transkripsiyon kotasını kullan',
  summary: 'Özet',
  query: 'Sorgu',
  mins_per_query: 'Sorgu başına {x} dakika',
  information_not_found: 'Bilgi bulunamadı',
  permission_deny: 'İzin reddedildi',
  please_login_to_check_the_result:
    'Sonucu kontrol etmek için lütfen giriş yapın',
  audio_length: 'Ses Süresi',
  delete_permanently: 'Kalıcı Olarak Sil',
  are_you_sure_to_delete_permanently:
    'Kalıcı olarak silmek istediğinizden emin misiniz?',
  delete_audios_move_to_bin:
    'Silinen kayıtlar çöp kutusuna taşınacak. Çöp kutusundaki tüm kayıtlar 30 gün sonra silinecektir.',
  payment_id: 'Ödeme Kimliği',
  current_plan: 'Geçerli Plan',
  plan_name: 'Plan adı',
  expire_date: 'Son Kullanma Tarihi',
  subscription_method: 'Abonelik yöntemi',
  payment_method: 'Ödeme yöntemi',
  payment_history: 'Ödeme geçmişi',
  amount: 'Tutar',
  invoice: 'Fatura',
  receipt: 'Makbuz',
  link: 'Bağlantı',
  edit: 'Düzenle',
  back: 'Geri',
  return: 'Dön',
  cancel_subscription: 'Aboneliği iptal et',
  cancel_subscription_google_play:
    "Android cihazınızda > Google Play Store'a gidin > Hesap > Ödemeler ve abonelikler > Abonelikler > SoundType AI > Aboneliği iptal et",
  cancel_subscription_apple_store:
    "iOS cihazınızda > Ayarlar'a gidin > [adınız] > Abonelikler > SoundType AI > Aboneliği iptal et",
  delete_account_des:
    'Hesabı silmek tüm verilerinizi kalıcı olarak silecektir.',
  delete_account_subscription:
    'Bir aboneliğiniz varsa, lütfen önce aboneliğinizi iptal edin.',
  keep_subscription: 'Aboneliği Sürdür',
  cancel_subscription_feature1: 'Kayıtlar',
  cancel_subscription_feature1_des:
    'Kayıtlarınızın sadece ilk {x} dakikasını görüntüleyebileceksiniz.',
  cancel_subscription_feature2: 'Transkripsiyon süresi',
  cancel_subscription_feature2_des:
    'Aylık sadece {x} dakika transkribe edebileceksiniz.',
  cancel_subscription_count: 'Onaylamak için {x} kez düğmeye basın',
  next_payment_date: 'Sonraki ödeme tarihi',
  basic_plan: 'Temel Plan',
  resume_subscription: 'Aboneliği devam ettir',
  active: 'Aktif',
  expired: 'Süresi dolmuş',
  auto_renewal_clause: 'Otomatik yenileme maddesi',
  auto_renewal_payment_web:
    'Aboneliğiniz, mevcut dönem bitmeden en az 24 saat önce iptal edilmediği sürece otomatik olarak yenilenecektir. Mevcut dönemin bitiminden önceki 24 saat içinde yenileme ücreti hesabınıza yansıtılacaktır. Aboneliklerinizi "Ayarlar" > "Faturalama" bölümünden yönetebilir ve iptal edebilirsiniz.',
  auto_renewal_terms_and_conditions:
    'Abone olarak, Şartlar ve Koşullarımızı ve Gizlilik Politikamızı kabul etmiş olursunuz.',
  payment: 'Ödeme',
  monthly: 'Aylık',
  annual: 'Yıllık',
  minutes_per_month: '{x} dakika/ay',
  buy_now: 'Şimdi Satın Al',
  yearly: 'Yıllık',
  yearly_save: 'En fazla {x}% tasarruf edin',
  pro_plan: 'Pro',
  business_plan: 'İş',
  enterprise_plan: 'Kurumsal',
  everything_in_basic: 'Temel plandaki her şey, artı',
  everything_in_pro: 'Pro plandaki her şey, artı',
  everything_in_business: 'İş plandaki her şey, artı',
  ai_chat_x: 'AI Sohbet x {x}',
  ai_summary_x: 'AI Özeti x {x}',
  max_x_minutes_per_transcription: 'Maks {x} dakika transkripsiyon başına',
  basic_des2: 'Konuşmacı tanımlama',
  basic_des3: 'Ses/video dosyası transkripsiyonu',
  basic_des4: 'Transkripsiyonları gözden geçirin ve düzenleyin',
  pro_des2: 'Öncelik Sırası',
  pro_des3: 'SRT formatında dışa aktar',
  pro_des4: 'Sınırsız AI Özeti',
  business_des1: 'Kullanım analitiği raporu',
  business_des2: 'Takım üyeleriyle işbirlikçi çalışma alanı',
  business_des3: 'Transkriptleri takımınızla paylaşın ve düzenleyin.',
  business_des4: 'Paylaşım izinlerini kontrol et',
  coming_soon: 'Yakında',
  get_started: 'Başlayın',
  bill_annually_x: 'Yıllık {x} olarak faturalandırılır',
  no_credit_card_required: 'Kredi kartı gerekmiyor',
  save_x: '{x}% tasarruf edin',
  price_per_month: ' / ay',
  not_available: 'Mevcut değil',
  pricing_title: 'Fiyatlandırma',
  upgrade: 'Yükselt',
  ask_question_to_your_audio: 'Sesinize soru sorun',
  monthly_minutes: 'Aylık dakikalar',
  payment_interval: 'Ödeme aralığı',
  annually: 'Yıllık',
  billing: 'Faturalandırma',
  subscription_plan: 'Plan',
  please_cancel_your_current_subscription_first:
    'Lütfen önce mevcut aboneliğinizi iptal edin',
  please_cancel_your_current_subscription_first_des:
    'Abonelik planınızı değiştirmek için önce mevcut aboneliğinizi iptal etmeniz gerekir.',
  cancel_subscription_instruction:
    'Ayarlar > Faturalandırma > Aboneliği İptal Et bölümüne gidin',
  website_version: 'Web Sitesi Sürümü',
  change_to_annual_plan: 'Yıllık Plana Geç',
  update_annual_mobile_platform_reminder: 'Hatırlatma',
  update_annual_mobile_platform_reminder_des:
    'Yıllık plana geçmeden önce {x} üzerindeki mevcut aboneliğinizi iptal etmeniz gerektiğini bildirin. Aksi takdirde iki kez ücretlendirileceksiniz.',
  i_understand: 'Anladım',
  no_credit: 'Yeterli dakika yok',
  re_submit: 'Yeniden gönder',
  loading_audio: 'Ses yükleniyor',
  your_password: 'Şifreniz',
  home: 'Ana Sayfa',
  title_a_z: 'Başlık A-Z',
  title_z_a: 'Başlık Z-A',
  older_first: 'Önce Eski',
  newest_first: 'Önce Yeni',
  table_item_count: '{x} - {y} / {z} öğe',
  starred: 'Yıldızlı',
  search: 'Ara',
  create_folder: 'Klasör oluştur',
  folder_name: 'Klasör adı',
  create: 'Oluştur',
  export_preview: 'Dışa Aktarma önizlemesi',
  combine_segments: 'Bölümleri birleştir',
  export_audio_only:
    'Ses yalnızca ses transkribe edilirken dışa aktarılabilir.',
  transcribe_remaining_x_minutes: 'Kalan {x} dakikayı transkribe et',
  x_minutes_remain_be_transcribed: '{x} dakika transkribe edilmek üzere kaldı',
  upgrade_plan_to_transcribe:
    'Tüm sesi transkribe etmek için planınızı yükseltin',
  only_paid_user_can_use:
    'Kalan dakikaları sadece ücretli kullanıcılar transkribe edebilir',
  converting_to_audio: 'Sese dönüştürülüyor',
  move_to_folder: 'Klasöre taşı',
  move_audios_to_folder: 'Seçilen sesleri klasöre taşı',
  please_select_folder: 'Lütfen bir klasör seçin',
  empty_folder: 'Boş klasör',
  move: 'Taşı',
  user_not_found:
    'Kullanıcı bulunamadı. Belirtilen kullanıcı bulunamadı. Bilgileri kontrol edin ve tekrar deneyin veya daha fazla yardım için destekle iletişime geçin.',
  invalid_link: 'Geçersiz bağlantı',
  translation: 'Çeviri',
  translate: 'Çevir',
  translate_des1:
    'Çeviri hizmeti, transkripsiyon sonucunun yapay zeka ile çevirisini sağlar. Transkripsiyon sonucunu başka bir dile çevirebilir.',
  translate_des2: 'Çevrilmiş metin şu anda düzenlenemez.',
  translate_des3: 'Eski çeviriniz yeni çeviri ile değiştirilecektir.',
  translating: 'Çevriliyor',
  translating_des:
    'Çeviri işlemi devam ediyor. Lütfen bir süre bekleyin. Çeviri hazır olduğunda sizi bilgilendireceğiz.',
  please_select_language: 'Lütfen bir dil seçin',
  display_language: 'Görüntüleme dili',
  both: 'Her ikisi',
  original: 'Orijinal',
  translated: 'Çevrilmiş',
  default_payment_currency_x_set:
    'Ödeme para biriminiz {x} olarak ayarlandı. Bunu değiştirmek istiyorsanız, lütfen {email} adresinden bizimle iletişime geçin.',
  invalid_link_des:
    'YouTube bağlantısı geçersiz. Canlı videolar, çalma listeleri, özel içerikler, yaş sınırı olan veya bölge kısıtlamalı içerikler desteklenmemektedir.',
  free_member_limited_view:
    'Temel plan kullanıcıları yalnızca transkripsiyon sonucunun ilk {x} dakikasına erişebilir.',
  free_user_service_once:
    'Temel plan kullanıcıları bu hizmeti yalnızca bir kez kullanabilir.',
  free_user_summary:
    'Temel plan kullanıcıları bu hizmeti yalnızca bir kez kullanabilir. Daha fazla ses dosyasını özetlemek için lütfen Pro planına yükseltin.',
  free_user_query:
    'Temel plan kullanıcıları bu hizmeti yalnızca bir kez kullanabilir. Daha fazla sorgu sormak için lütfen Pro planına yükseltin.',
  cancel_subscription_des:
    'Aboneliğiniz mevcut fatura dönemi sonunda ({x}) iptal edilecektir. Bu tarihten sonra Temel plana düşürüleceksiniz ve Pro planın özelliklerine erişiminiz olmayacaktır:',
  free_member_limited_transcribe_view:
    'Yalnızca ilk {x} dakika transkribe edilecek ve Temel plan kullanıcıları tarafından görülebilecektir.',
  reset_password_email_sent:
    'Lütfen e-postanızı kontrol edin. Bu hesap mevcutsa, bir şifre sıfırlama e-postası gönderilecektir.',
  m_downloads: '1M+ İndirme',
  provider: 'Sağlayıcı',
  size: 'Boyut',
  category: 'Kategori',
  productivity: 'Verimlilik',
  languages: 'Diller',
  languages_90: '90+ dil',
  app_des:
    "Konuşulan kelimeleri SoundType AI ile zahmetsizce metne dönüştürün! Gelişmiş uygulamamız, ses kayıtlarını ve ses dosyalarını son derece doğru metin tabanlı transkripsiyonlara dönüştürür. Toplantılar, röportajlar, podcast'ler veya video içerikleriyle çalışıyor olsanız da SoundType AI hassasiyet ve verimlilik sunar.<br><br>Öne çıkan özellikler:<br>- Gelişmiş Konuşmacı Tanımlama: Bireysel konuşmacıları sorunsuz bir şekilde ayırt edin.<br>- Yapay Zeka Destekli Özetler: Transkripsiyonlarınızın özlü ve bilgi dolu özetlerini oluşturun.<br>- Küresel Dil Desteği: 90'dan fazla dilde konuşmaları kolayca transkribe edin.<br>- Gelişmiş Ses Özellikleri: Profesyoneller, içerik oluşturucular ve işletmeler için tasarlandı.<br><br>Zahmetli manuel transkripsiyonlara veda edin ve SoundType AI ile sesi metne dönüştürmenin daha akıllı ve hızlı bir yolunu keşfedin.",
  click_or_drop_file: 'Tıklayın veya ses / video dosyasını bırakın',
  remain_x_y_minutes: 'Kalan {x}/{y} dakika',
  my_account: 'Hesabım',
  please_fill_your_email: 'Lütfen e-posta adresinizi doldurun',
  please_select_language_first: 'Lütfen önce bir dil seçin',
  please_select_audio_first: 'Lütfen önce bir ses dosyası seçin',
  please_fill_youtube_link: 'Lütfen YouTube bağlantısını doldurun',
  speaker_person_x: 'Konuşmacı {x}',
  sign_up_to_unlock_des:
    'Transkripsiyonları düzenlemek, dosyaları dışa aktarmak ve AI destekli özetlere, çevirilere ve daha fazlasına erişmek için kaydolun. Bugün iş akışınızı basitleştirin!',
  your_audio_being_transcribed: 'Sesiniz yazıya dökülüyor...',
  sign_up_to_unlock: 'Daha fazla özelliği keşfetmek için kaydolun',
  try_for_free_title: 'Ücretsiz Deneyin',
  try_for_free_subtitle:
    'Gelişmiş AI Gücü, Üstün Doğruluk ve İleri Düzey Özellikleri Deneyimleyin',
  purchase_success: 'Ödeme Başarılı!',
  purchase_success_des:
    'Satın alımınız için teşekkür ederiz. İşleminiz başarıyla tamamlandı.',
  auto_redirect_to_dashboard:
    'Kısa süre içinde panonuza yönlendirileceksiniz. Yönlendirme 5 saniye içinde gerçekleşmezse, manuel olarak devam etmek için aşağıdaki bağlantıya tıklayın:',
  need_assistance: 'Yardıma mı ihtiyacınız var? Bize {email} adresinden ulaşın',
  invalid_format: 'Geçersiz format',
  top_up: 'Bakiye Yükleme',
  valid_x_months: 'Geçerli olduğu plan ayı ({x}).',
  top_up_des:
    'Yüklenen dakikalar yalnızca mevcut plan ayında kullanılabilir. Kalan veriler bir sonraki aya aktarılamaz.',
  basic_plan_user_cant_top_up:
    'Temel plan kullanıcıları dakika yükleyemez. Bakiye yükleme seçeneklerini etkinleştirmek için lütfen planınızı yükseltin.',
}
