import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'

export default async function stripeCheckout(
  { client, config }: ApiContext,
  price_id: string,
  uid: number,
  success_url: string,
  cancel_url: string,
  locate = 'en',
  currency = null,
  mode = 'subscription',
) {
  const response = await client.purchases.stripeCheckout(
    AuthHelper.getAccessToken(),
    {
      price_id,
      uid,
      success_url,
      cancel_url,
      locate,
      currency,
      mode,
    },
  )
  if (response.isSuccess()) {
    const success = response.success()
    // console.log(success.url)
    return success['url']
  } else {
    console.log('stripeCheckout: fail')
    throw response.fail()
  }
}
