export const ko = {
  sign_in: '로그인',
  email: '이메일',
  password: '비밀번호',
  i_agree_to_toc: '약관에 동의합니다.',
  terms_and_conditions: '이용 약관',
  privacy_policy: '개인 정보 처리 방침',
  already_have_an_account: '이미 계정이 있으신가요?',
  sign_up: '가입하기',
  dont_have_an_account: '계정이 없으신가요?',
  forgot_password: '비밀번호를 잊으셨나요?',
  email_already_in_use: '사용 중인 이메일입니다',
  weak_password: '약한 비밀번호',
  too_many_attempts: '시도 횟수가 너무 많습니다',
  invalid_email_or_password: '유효하지 않은 이메일이나 비밀번호입니다',
  user_already_registered: '이미 등록된 사용자입니다',
  unexpected_error: '예상치 못한 오류',
  audios: '오디오',
  name: '이름',
  auto: '자동',
  auto_detect: '자동 감지',
  speaker_num: '스피커 수',
  speaker_num_reason:
    '스피커 수를 알려주시면 더 나은 전사 결과를 제공해 드립니다.',
  audio_language: '오디오 언어',
  your_email: '이메일',
  email_result: '전사 결과를 이메일로 보내드립니다.',
  provide_language:
    '오디오 언어를 알려주시면 더 나은 전사 결과를 제공해 드립니다.',
  upload_audio: '오디오 업로드',
  upload_file: '파일 업로드',
  process_audio: '파일 처리 중',
  process_audio_des:
    '오디오 전사 작업이 진행 중입니다. 몇 분 정도 소요될 수 있습니다.',
  uploading: '업로드 중',
  pending: '대기 중',
  uploaded: '업로드 완료',
  failed: '실패',
  status: '상태',
  language: '언어',
  speakers: '스피커',
  duration: '시간',
  done: '완료',
  close: '닫기',
  queuing: '대기 중',
  created_at: '작성일',
  records: '기록',
  transcribe: '전사',
  delete: '삭제',
  processing: '처리 중',
  from_youtube: 'YouTube에서 가져오기',
  upload_files: '파일 업로드',
  place_youtube_link_here: 'YouTube 링크를 입력하세요',
  submit: '제출',
  email_notification:
    '전사 작업은 오디오 파일의 길이와 복잡성에 따라 소요 시간이 달라집니다. 일반적으로 몇 시간 안에 전사 결과를 받으실 수 있습니다. 작업이 완료되면 이메일로 알려드립니다.',
  filters: '필터',
  filter: '필터',
  replace: '바꾸기',
  share_and_export: '공유 및 내보내기',
  replace_all: '모두 바꾸기',
  next: '다음',
  previous: '이전',
  find: '찾기',
  replace_with: '바꿀 내용',
  x_of_x: '{current} / {total}',
  scroll_lock: '스크롤 잠금',
  tags: '태그',
  bookmark: '북마크',
  audio: '오디오',
  cancel: '취소',
  export: '내보내기',
  export_format: '내보낼 형식',
  copy_text: '텍스트 복사',
  monologue: '독백',
  include_timestamps: '타임스탬프 포함',
  include_speaker_names: '스피커 이름 포함',
  combine_same_speaker: '동일한 스피커 세그먼트 병합',
  export_mode: '내보내기 모드',
  no_preview: '미리보기 없음',
  copy: '복사',
  copied: '복사됨',
  unlock_pro_feature:
    '이 기능을 사용하려면 "프로" 구독 요금제로 업그레이드하세요.',
  pro_features: '프로 기능',
  pro_minutes: '1800분 / 월',
  pro_interact_with_audio: '오디오와 상호 작용하기',
  pro_interact_with_audio_des: '오디오에 질문하기',
  pro_summary: 'AI 요약',
  pro_summary_des: '오디오 요약',
  pro_priority: '우선순위 대기열',
  pro_priority_des: '우선 처리되는 오디오',
  pro_multi_speakers: '다중 스피커 인식',
  pro_multi_speakers_des: '다른 스피커를 정확하게 감지하고 레이블 지정',
  redirect_to_stripe: '결제를 완료하기 위해 Stripe로 리디렉션됩니다.',
  x_per_month: '{x} / 월',
  x_per_year: '{x} / 년',
  trash: '휴지통',
  dashboard: '대시보드',
  logout: '로그아웃',
  are_you_sure_to_delete: '선택한 기록을 삭제하시겠습니까?',
  restore: '복원',
  are_you_sure_to_restore: '선택한 기록을 복원하시겠습니까?',
  deleted_at: '삭제일',
  hidden_segment: '{y}분 이후 {x}개 세그먼트가 숨겨졌습니다.',
  settings: '설정',
  subscription: '구독',
  default_transcription_language: '기본 전사 언어',
  contact_us: '문의하기',
  security: '보안',
  about_us: '회사 소개',
  download_app: '앱 다운로드',
  download: '다운로드',
  app_store: '앱 스토어',
  play_store: '플레이 스토어',
  upload_voice: '음성 업로드',
  no_audio_found: '오디오를 찾을 수 없음',
  please_input_valid_email_first: '유효한 이메일을 입력하세요',
  handling_audio: '오디오를 전사 중입니다. 잠시 기다려주세요.',
  welcome_to_leave:
    '이 페이지를 나가셔도 괜찮습니다. 전사 결과를 이메일로 보내드립니다.',
  send_your_result: '전사 결과를 이메일로 보내드립니다.',
  transcribe_audio_thank:
    '전사 서비스를 이용해 주셔서 감사합니다! 오디오를 전사하는 작업에 힘쓰고 있으며, 여러분의 참여에 감사드립니다.',
  transcribe_audio_no_stay:
    '전사 작업은 약간의 시간이 걸릴 수 있지만, 걱정하지 마세요. 페이지에 머무를 필요는 없습니다. 결과가 준비되면 이메일로 알려드립니다.',
  date: '날짜',
  sign_up_to_access_more_functions: '더 많은 기능을 이용하려면 가입하세요',
  you_can_use_functions: '로그인 후 다음 기능을 이용할 수 있습니다',
  know_more: '더 알아보기',
  modify_transcription: '텍스트 전사 수정',
  play_audio: '오디오 재생',
  content_filters: '콘텐츠 필터',
  export_diff_type: '다른 유형의 파일 내보내기',
  screenshots: '스크린샷',
  free_trial: '무료 체험',
  pricing: '가격',
  faq: '자주 묻는 질문',
  contact: '문의',
  login: '로그인',
  register: '가입',
  please_login_before_purchase: '구매 전에 로그인하세요',
  no_records_found: '기록을 찾을 수 없습니다',
  x_minutes: '{x} 분',
  confirm: '확인',
  notification_mobile_app: '모바일 앱 알림',
  notification_mobile_app_des:
    '전사 결과가 준비되었을 때 모바일 앱 알림을 받습니다',
  notification_email: '이메일 알림',
  notification_email_des: '전사 결과가 준비되었을 때 이메일 알림을 받습니다',
  video: '비디오',
  or: '또는',
  sign_in_with_google: 'Google로 로그인',
  sign_in_with_apple: 'Apple로 로그인',
  login_method: '로그인 방법',
  delete_account: '계정 삭제',
  delete_account_confirm_3_time: '확인하려면 삭제 버튼을 3번 누르세요',
  popup_blocked: '팝업이 차단되었습니다',
  account_info: '계정 정보',
  redeem: '사용',
  redeem_code: '코드 사용',
  summary_des1:
    '요약 서비스는 전사 결과를 요약하여 제공합니다. 전사 결과를 몇 문장으로 요약할 수 있습니다.',
  summary_des2: '기존 요약이 새로운 요약으로 대체됩니다.',
  consume_transcription_quota: '전사 할당량 소비',
  summary: '요약',
  query: '질문',
  mins_per_query: '질문당 {x}분',
  information_not_found: '정보를 찾을 수 없습니다',
  permission_deny: '권한 거부',
  please_login_to_check_the_result: '결과를 확인하기 위해 로그인하십시오',
  audio_length: '오디오 길이',
  delete_permanently: '영구적으로 삭제',
  are_you_sure_to_delete_permanently: '영구히 삭제하시겠습니까?',
  delete_audios_move_to_bin:
    '삭제된 레코드는 휴지통으로 이동됩니다. 휴지통의 모든 레코드는 30일 후에 삭제됩니다.',
  payment_id: '결제 ID',
  current_plan: '현재 플랜',
  plan_name: '플랜 이름',
  expire_date: '만료 날짜',
  subscription_method: '구독 방법',
  payment_method: '결제 방법',
  payment_history: '결제 내역',
  amount: '금액',
  invoice: '송장',
  receipt: '영수증',
  link: '링크',
  edit: '편집',
  back: '뒤로',
  return: '반환',
  cancel_subscription: '구독 취소',
  cancel_subscription_google_play:
    '안드로이드 기기에서 > Google Play 스토어로 이동 > 계정 > 결제 및 구독 > 구독 > SoundType AI > 구독 취소',
  cancel_subscription_apple_store:
    'iOS 기기에서 > 설정으로 이동 > [당신의 이름] > 구독 > SoundType AI > 구독 취소',
  delete_account_des: '계정을 삭제하면 모든 데이터가 영구적으로 삭제됩니다.',
  delete_account_subscription: '구독이 있다면, 먼저 구독을 취소해주세요.',
  keep_subscription: '구독 유지',
  cancel_subscription_feature1: '녹음',
  cancel_subscription_feature1_des: '녹음의 처음 {x}분만 볼 수 있습니다.',
  cancel_subscription_feature2: '전사 시간',
  cancel_subscription_feature2_des: '매월 {x}분만 전사할 수 있습니다.',
  cancel_subscription_count: '확인을 위해 버튼을 {x}번 눌러주세요',
  next_payment_date: '다음 결제 날짜',
  basic_plan: '기본 플랜',
  resume_subscription: '구독 재개',
  active: '활성',
  expired: '만료됨',
  auto_renewal_clause: '자동 갱신 조항',
  auto_renewal_payment_web:
    '현재 기간이 끝나기 최소 24시간 전에 구독을 취소하지 않으면 구독이 자동으로 갱신됩니다. 계정은 현재 기간이 끝나기 24시간 이내에 갱신 비용이 청구됩니다. 구독 관리는 "설정" > "청구"에서 할 수 있습니다.',
  auto_renewal_terms_and_conditions:
    '구독함으로써 당사의 이용약관 및 개인정보 보호정책에 동의하는 것입니다.',
  payment: '결제',
  monthly: '월간',
  annual: '연간',
  minutes_per_month: '{x} 분/월',
  buy_now: '지금 구매',
  yearly: '연간',
  yearly_save: '{x}%까지 절약',
  pro_plan: '프로',
  business_plan: '비즈니스',
  enterprise_plan: '엔터프라이즈',
  everything_in_basic: '기본 포함 사항 외',
  everything_in_pro: '프로 포함 사항 외',
  everything_in_business: '비즈니스 포함 사항 외',
  ai_chat_x: 'AI 채팅 x {x}',
  ai_summary_x: 'AI 요약 x {x}',
  max_x_minutes_per_transcription: '전사당 최대 {x}분',
  basic_des2: '화자 식별',
  basic_des3: '오디오/비디오 파일 전사',
  basic_des4: '전사문 검토 및 편집',
  pro_des2: '우선 순위 큐',
  pro_des3: 'SRT 포맷으로 내보내기',
  pro_des4: '무제한 AI 요약',
  business_des1: '사용량 분석 보고서',
  business_des2: '팀 멤버와 협업 작업 공간',
  business_des3: '팀 내에서 전사문 공유 및 편집.',
  business_des4: '공유 권한 제어',
  coming_soon: '곧 출시 예정',
  get_started: '시작하기',
  bill_annually_x: '연간 청구 {x}',
  no_credit_card_required: '신용카드 필요 없음',
  save_x: '{x}% 절약',
  price_per_month: ' / 월',
  not_available: '사용 불가',
  pricing_title: '가격 책정',
  upgrade: '업그레이드',
  ask_question_to_your_audio: '오디오에 질문하기',
  monthly_minutes: '월간 분',
  payment_interval: '결제 간격',
  annually: '연간',
  billing: '청구',
  subscription_plan: '구독 플랜',
  please_cancel_your_current_subscription_first:
    '현재 구독을 먼저 취소해 주세요',
  please_cancel_your_current_subscription_first_des:
    '구독 플랜을 변경하려면 현재 구독을 먼저 취소해야 합니다.',
  cancel_subscription_instruction: '설정 > 청구 > 구독 취소로 이동',
  website_version: '웹사이트 버전',
  change_to_annual_plan: '연간 플랜으로 변경',
  update_annual_mobile_platform_reminder: '알림',
  update_annual_mobile_platform_reminder_des:
    '연간 플랜으로 변경하기 전에 {x}에서 현재 구독을 취소해야 함을 알려드립니다. 그렇지 않으면 두 번 청구될 수 있습니다.',
  i_understand: '이해했습니다',
  no_credit: '충분한 분이 없습니다',
  re_submit: '다시 제출',
  loading_audio: '오디오 로딩 중',
  your_password: '당신의 비밀번호',
  home: '홈',
  title_a_z: '제목 A-Z',
  title_z_a: '제목 Z-A',
  older_first: '오래된 것부터',
  newest_first: '최신순',
  table_item_count: '{x} - {y} 항목 중 {z} 개',
  starred: '별표 표시됨',
  search: '검색',
  create_folder: '폴더 만들기',
  folder_name: '폴더 이름',
  create: '생성',
  export_preview: '미리보기 내보내기',
  combine_segments: '구간 결합',
  export_audio_only: '오디오가 전사 중일 때만 오디오를 내보낼 수 있습니다.',
  transcribe_remaining_x_minutes: '남은 {x} 분 전사하기',
  x_minutes_remain_be_transcribed: '전사할 {x} 분이 남았습니다',
  upgrade_plan_to_transcribe:
    '전체 오디오를 전사하려면 요금제를 업그레이드하세요',
  only_paid_user_can_use: '유료 사용자만 남은 시간을 전사할 수 있습니다',
  converting_to_audio: '오디오로 변환 중',
  move_to_folder: '폴더로 이동',
  move_audios_to_folder: '선택한 오디오를 폴더로 이동',
  please_select_folder: '폴더를 선택하십시오',
  empty_folder: '빈 폴더',
  move: '이동',
  user_not_found:
    '사용자를 찾을 수 없습니다. 지정된 사용자를 찾을 수 없습니다. 세부 정보를 확인하고 다시 시도하거나 추가 지원이 필요하면 지원팀에 문의하십시오.',
  invalid_link: '유효하지 않은 링크',
  translation: '번역',
  translate: '번역하기',
  translate_des1:
    '번역 서비스는 전사 결과의 AI 번역을 제공합니다. 전사 결과를 다른 언어로 번역할 수 있습니다.',
  translate_des2: '번역된 텍스트는 현재 편집할 수 없습니다.',
  translate_des3: '이전 번역은 새로운 번역으로 교체됩니다.',
  translating: '번역 중',
  translating_des:
    '번역 과정이 진행 중입니다. 잠시 기다려주십시오. 번역이 준비되면 알려드리겠습니다.',
  please_select_language: '언어를 선택하십시오',
  display_language: '표시 언어',
  both: '둘 다',
  original: '원본',
  translated: '번역된',
  default_payment_currency_x_set:
    '결제 통화가 {x}로 설정되었습니다. 변경을 원하시면 {email}로 문의해 주세요.',
  invalid_link_des:
    'YouTube 링크가 유효하지 않습니다. 라이브 영상, 재생목록, 비공개, 연령 제한 또는 지역 제한 콘텐츠는 지원되지 않습니다.',
  free_member_limited_view:
    '베이직 플랜 사용자는 트랜스크립션 결과의 처음 {x}분만 액세스할 수 있습니다.',
  free_user_service_once:
    '베이직 플랜 사용자는 이 서비스를 한 번만 사용할 수 있습니다.',
  free_user_summary:
    '베이직 플랜 사용자는 이 서비스를 한 번만 사용할 수 있습니다. 더 많은 오디오를 요약하려면 프로 플랜으로 업그레이드하세요.',
  free_user_query:
    '베이직 플랜 사용자는 이 서비스를 한 번만 사용할 수 있습니다. 더 많은 질문을 하려면 프로 플랜으로 업그레이드하세요.',
  cancel_subscription_des:
    '현재 청구 주기 ({x})가 끝나면 구독이 취소됩니다. 해당 날짜 이후에는 베이직 플랜으로 다운그레이드되며 프로 플랜 기능에 더 이상 액세스할 수 없습니다.',
  free_member_limited_transcribe_view:
    '처음 {x}분만 트랜스크립트되며 베이직 플랜 사용자가 볼 수 있습니다.',
  reset_password_email_sent:
    '이메일을 확인하세요. 계정이 존재하는 경우 비밀번호 재설정 이메일이 발송됩니다.',
  m_downloads: '100만+ 다운로드',
  provider: '제공자',
  size: '크기',
  category: '카테고리',
  productivity: '생산성',
  languages: '언어',
  languages_90: '90개 이상의 언어',
  app_des:
    'SoundType AI로 말한 단어를 텍스트로 손쉽게 변환하세요! 최첨단 앱이 음성 녹음 및 오디오 파일을 고도로 정확한 텍스트 기반 트랜스크립트로 변환합니다. 회의, 인터뷰, 팟캐스트 또는 비디오 콘텐츠 작업 시에도 SoundType AI는 정확성과 효율성을 제공합니다.<br><br>주요 기능:<br>- 고급 화자 식별: 개별 화자를 쉽게 구분.<br>- AI 기반 요약: 트랜스크립트의 간결하고 유익한 요약 생성.<br>- 글로벌 언어 지원: 90개 이상의 언어로 대화를 손쉽게 트랜스크립트.<br>- 강화된 오디오 기능: 전문가, 창작자 및 비즈니스에 적합하게 설계.<br><br>번거로운 수동 트랜스크립트와 작별하고 SoundType AI로 오디오를 텍스트로 변환하는 더 똑똑하고 빠른 방법을 도입하세요.',
  click_or_drop_file: '클릭 또는 오디오 / 비디오 드롭',
  remain_x_y_minutes: '남은 시간 {x}/{y} 분',
  my_account: '내 계정',
  please_fill_your_email: '이메일을 입력해주세요',
  please_select_language_first: '먼저 언어를 선택해주세요',
  please_select_audio_first: '먼저 오디오를 선택해주세요',
  please_fill_youtube_link: '유튜브 링크를 입력해주세요',
  speaker_person_x: '발표자 {x}',
  sign_up_to_unlock_des:
    '전사 수정, 파일 내보내기, AI 기반 요약 및 번역 등에 액세스하려면 가입하세요. 오늘부터 워크플로를 간소화하세요!',
  your_audio_being_transcribed: '오디오가 전사되고 있습니다...',
  sign_up_to_unlock: '더 많은 기능을 탐색하려면 가입하세요',
  try_for_free_title: '무료로 체험하기',
  try_for_free_subtitle:
    '강화된 AI 파워, 우수한 정확도 및 고급 기능을 경험하세요',
  purchase_success: '결제 완료!',
  purchase_success_des:
    '구매해 주셔서 감사합니다. 거래가 성공적으로 완료되었습니다.',
  auto_redirect_to_dashboard:
    '곧 대시보드로 리디렉션됩니다. 5초 이내에 리디렉션되지 않으면 다음 링크를 클릭하여 수동으로 진행하십시오:',
  need_assistance: '도움이 필요하십니까? {email}로 문의하십시오',
  invalid_format: '잘못된 형식',
  top_up: '충전',
  valid_x_months: '현재 플랜 월 ({x}) 동안 유효합니다.',
  top_up_des:
    '충전한 분은 현재 플랜 월에만 사용할 수 있습니다. 남은 데이터는 다음 달로 이월되지 않습니다.',
  basic_plan_user_cant_top_up:
    '베이직 플랜 사용자는 충전할 수 없습니다. 충전 옵션을 활성화하려면 플랜을 업그레이드하세요.',
}
